import { observable } from 'mobx';
import { each, get } from 'lodash';
import { Model, Store, ViewStore } from '@code-yellow/spider';
import { DateTime } from 'luxon';
import { t } from 'i18n';
import { ProductionOrder } from 'store/Planning/ProductionOrder';

declare global {
    interface Window {
        t: typeof t;
        viewStore: ViewStore;
    }
}

export const TAB_TITLE_PREFIX = 'FreshFlow';
export const FRONTEND_API_BASE_URL = process.env.REACT_APP_CY_FRONTEND_API_BASE_URL || '/api/';

// Config set by bootstrap.
export let MAPS_API_KEY = '';
export let MAPS_API_URL = '';
export const BUILD_INFO = observable({
    version: 'dev',
});

export function configOverride(bootstrap) {
    MAPS_API_KEY = bootstrap.google_maps_api_key;
    MAPS_API_URL = bootstrap.google_maps_api_url;
    Object.assign(BUILD_INFO, bootstrap.build_info);
}

// Stolen from re-cy-cle
// lodash's `camelCase` method removes dots from the string; this breaks mobx-spine
export function snakeToCamel(s) {
    if (s.startsWith('_')) {
        return s;
    }
    return s.replace(/_\w/g, m => m[1].toUpperCase());
}

// lodash's `snakeCase` method removes dots from the string; this breaks mobx-spine
export function camelToSnake(s) {
    return s.replace(/([A-Z])/g, $1 => '_' + $1.toLowerCase());
}

// TODO: make separate helper files categorized by theme, e.g. "money" and "date"
// This is insane at the moment, sorry man.

export const PUBLIC_URL =
    process.env.NODE_ENV !== 'production' ? process.env.PUBLIC_URL : '';

// While in debug mode, customer ids can be filtered here. It speeds up page
// loading and is automatically disabled on production to prevent goldplated ids
// going live.
export const ALLOCATION_IDS = [569, 290]; //[410, 414];

export const IS_DEBUG = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// Also used by mobile, which has a window, but no location?
export const IS_STAGE = typeof window !== 'undefined' && /staging|stage/.test(window.location.href);
export const IS_UAT = typeof window !== 'undefined' && window.location.href.includes('uat');

// Feature flags
export const FLAG_ACTIVITY_ISSUES = IS_DEBUG || IS_STAGE;

export function floatToDecimal(value) {
    return value.toFixed(2).replace('.', ',');
}

// Stolen from https://gist.github.com/penguinboy/762197#gistcomment-2380871
const flatten = (object, prefix = '') => {
    return Object.keys(object).reduce((prev, element) => {
        return typeof object[element] === 'object'
            ? { ...prev, ...flatten(object[element], `${prefix}${element}.`) }
            : { ...prev, ...{ [`${prefix}${element}`]: object[element] } }
    }, {});
}

/**
 * Get list of error messages from the backend response. Typical usage:
 *
 * model.save().catch(response =>
 *     parseBackendErrorMessages(response.response.data.errors)
 * )
 */
export function parseBackendErrorMessages(errors) {
    const messages: string[] = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.message')) {
            messages.push(flat[key]);
        }
    });

    return messages;
}

export function parseBackendErrorCodes(errors) {
    const codes: string[] = [];
    const flat = flatten(errors);

    Object.keys(flat).forEach(key => {
        if (key.includes('.code')) {
            codes.push(flat[key]);
        }
    });

    return codes;
}

export function decimalToFloat(value) {
    if (typeof value !== 'string') {
        return null;
    }
    return parseFloat(value.replace(/\./g, '').replace(',', '.'));
}

export const SCREEN_WIDTH_PX = '1280px';

export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_SECONDS = 'HH:mm:ss';
export const DATE_RANGE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
export const ACTION_DELAY = 300;

export function setMoneyForBackend(value, decimals=2) {
    if (typeof value !== 'string') {
        return 0;
    }

    const parsed = decimalToFloat(value);

    if (!parsed) {
        return 0;
    }

    return isFinite(parsed) ? parsed * Math.pow(10, decimals) : 0;
}

const moneyFormat = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol'
});

export function formatMoney(value, decimals = 2) {
    // Better money formatter, which prefixed the euro symbol. We're not yet
    // ready for this...
    //
    // There is a small but important difference with how MoneyInput formats
    // negative numbers. The negative sign must come first, and
    // Intl.NumberFormat sets the negative sign after the € sign.
    const formatted = moneyFormat.format(value / Math.pow(10, decimals)).split(' ').join('');

    if (formatted.includes('-')) {
        return formatted[1] + formatted[0] + formatted.slice(2);
    }

    return formatted;
}

export function getMoneyForUser(value, decimals=2) {
    if (typeof value !== 'number') {
        return null;
    }

    return (value / Math.pow(10, decimals)).toFixed(decimals).replace('.', ',');

}

// It is possible that in a <select> component, the currently selected model
// is not present in the list; either it is deleted, or the store has pagination, etc.
export function addSelectedModelInOptions(models, selectedModel) {
    const newModels = models.filter();
    if (selectedModel.id && !newModels.find(m => m.id === selectedModel.id)) {
        newModels.push(selectedModel);
    }
    return newModels;
}

// Accepts a request error, and transforms it into an array
// of notification messages.
export function formatCustomValidationErrors(err) {
    let output = [];

    each(get(err, 'response.data.errors'), (errors, resource) => {
        output = output.concat(
            errors.map((e, i) => {
                return {
                    key: `${resource}${i}`,
                    message: e.message,
                    dismissAfter: 4000,
                };
            })
        );
    });
    return output;
}

export const BOOL_OPTIONS = [
    { value: 'true', text: t('form.yes') },
    { value: undefined, text: t('form.either') },
    { value: 'false', text: t('form.no') },
];

export interface ScreenProps {
    match: {
        params: any;
    };
    history: any;
    viewStore: ViewStore
}

export interface AfterSaveOptions {
    followNext?: boolean;
    goBack?: boolean;
    next?: string;
}

/**
 * Function to generate a contrasting text color (black/white) for a given color
 *
 * Used for example for elements which have dynamically set background colors
 * which also have text in the foreground that should remain readable for both
 * dark and bright background colors.
 *
 * @param hex color for which to find a contrasting text color
 * @returns hex code of the chosen contrasting text color
 */
export function getContrastTextColor(hex: string): string {
    // Function to convert hex to RGB
    const hexToRgb = (hex: string): [number, number, number] => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    };

    // Function to calculate the contrast color
    const getContrastColor = (r: number, g: number, b: number): string => {
        const brightness = r * 0.299 + g * 0.587 + b * 0.114;
        return brightness > 186 ? '#000000' : '#ffffff';
    };

    const [r, g, b] = hexToRgb(hex);
    return getContrastColor(r, g, b);
}


/**
 * Adjusts the RGB values of a hex color based on the provided adjustment function.
 *
 * @param hexColor The color to adjust (must be a 6-digit hex code)
 * @param adjustFn A function that takes an RGB component (0-255) and returns the adjusted component
 * @returns The adjusted hex color
 */
function adjustHexColor(hexColor: string, adjustFn: (component: number) => number): string {
    // Ensure the hex color starts with '#'
    if (!hexColor.startsWith('#')) {
        hexColor = '#' + hexColor;
    }

    // Remove the '#' character
    hexColor = hexColor.slice(1);

    // Convert hex to RGB
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);

    // Adjust the RGB components using the provided adjustment function
    const newR = adjustFn(r);
    const newG = adjustFn(g);
    const newB = adjustFn(b);

    // Convert the new RGB values back to hex
    const newHexColor = `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;

    return newHexColor;
}

/**
 * Darkens the given hex color by reducing each RGB component by a fixed percentage.
 *
 * @param hexColor The color to darken (must be a 6-digit hex code)
 * @returns A darker hex color
 */
export function darkenHex(hexColor: string): string {
    const DARKEN_FACTOR = 0.2; // The factor by which to darken the color

    return adjustHexColor(hexColor, (component) =>
        Math.max(0, Math.round(component * (1 - DARKEN_FACTOR)))
    );
}

/**
 * Applies a white overlay of the given alpha to the given color and returns a non-transparent hex.
 *
 * @param hexColor The color to which to apply the white overlay (must be a 6-digit hex)
 * @param alpha The alpha value of the applied white overlay
 * @returns The hex color with the overlay applied
 */
export function applyWhiteOverlay(hexColor: string, alpha: number): string {
    return adjustHexColor(hexColor, (component) =>
        Math.round(component * (1 - alpha) + 255 * alpha)
    );
}

/**
 * Turns URL (object) into a proper file URL
 *
 * Function copied from Spider where it's unfortunately not exported.
 */
export function getFileUrl(url) {
    if (url === null || typeof url === 'string') {
        return url;
    } else {
        return `${url.preview || URL.createObjectURL(url)}?content_type=${url.type}&filename=${url.name}`;
    }
}

/**
 * Helper to format a given date object to a given format
 *
 * @param date Luxon DateTime/Date object
 * @param format string the requested date format
 * @param defaultFormat string the default response if the date doesn't exist
 * @returns string formatted date
 */
export function format(date, format, defaultFormat = '') {
    if (date) {
        return date.toFormat(format);
    }

    return defaultFormat;
}


export type ContentItem<M extends Model = Model> = { date: DateTime | null } | (M & { date: DateTime | null });

/**
 * Algorithm to batch store models by date, interjected with a date object
 *
 * Requires a store/model with a 'date' field
 *
 * The algorithm prepends every batch of models on the same date by an object
 * containing only a `date` field, with the date of that batch. An example use
 * case is used to render dividers between batches.
 */
export function constructContentList<S extends Store = Store, M extends Model = Model>(store: S) {
    const contentList: ContentItem<M>[] = [];
    const sorted = store.models.sort((a, b) => (a.date ?? 0) - (b.date ?? 0));

    sorted.forEach((model: M & { date: DateTime | null }) => {
        const clLength = contentList.length;

        // Base case, add a datestamp and the message
        if (clLength === 0) {
            contentList.push({ date: model.date });
            contentList.push(model);
            return;
        }

        // Select the previous message. If the previous element is a datestamp,
        // then the element before it must be a message by construction.
        const prevItem: ContentItem<M> = 'id' in contentList[clLength - 1] ? contentList[clLength - 1] : contentList[clLength - 2];

        // As a result of the list structure, this case can never occur, but as
        // Typescript isn't aware of the list structure, this is required.
        if (!('id' in prevItem)) {
            return;
        }

        // Insert line element if current date and previous date aren't equal
        if (model.date && prevItem.date && model.date.toFormat('ddLLyyyy') !== prevItem.date.toFormat('ddLLyyyy')) {
            contentList.push({ date: model.date });
        }

        contentList.push(model);
    })

    return contentList;
}


/**
 * Truncates or extends a given array to a specified length (out-of-place)
 *
 * Example usage:
 *
 * - If the specified length is shorter than the current length, the array is truncated.
 *   Call:
 *       truncateOrFillArray([{ foo: 10 }, { bar: 5 }], 1, { baz: 50 })
 *   Returns:
 *       [{ foo: 10 }]
 *
 * - If the specified length is longer than the current length, the array is extended.
 *   Call:
 *       truncateOrFillArray([{ foo: 10 }, { bar: 5 }], 4, { baz: 50 })
 *   Returns:
 *       [{ foo: 10 }, { bar: 5 }, { baz: 50 }, { baz: 50 }]
 *
 * @param {T[]} myArray - The original array.
 * @param {number} i - The desired length of the new array.
 * @param {T} myObject - The object to fill the array with if the array has fewer than i items.
 * @returns {T[]} - A new array of length i.
 */
export function truncateOrFillArray<T>(myArray: T[], i: number, myObject?: T): T[] {
    return myArray.slice(0, i).concat(Array(Math.max(i - myArray.length, 0)).fill(myObject));
}

export function getPOProductionTimeCells(productionOrder: ProductionOrder) {
    return (productionOrder.productionTimeMinutes ?? 0) / 10;
}

export function delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

